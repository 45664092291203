@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.fund-intro-fund-box{
 background: #FFFFFF;
box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
border-radius: 10px;
width:12rem;
height:4rem;
 margin-bottom: 2rem;
}
.fund-intro-fund-box div{
  padding-top:7px;
  padding-left:14px;

}
.fund-intro-amount{
font-weight: 600;
font-size: 20px;
line-height: 24px;
}
.fund-intro-fund-text{
  font-weight: 600;
font-size: 14px;
line-height: 17px;  
}
.cf_container {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: "Montserrat", sans-serif;
}

.cf_car {
  border-radius: 20px;
  background-image: linear-gradient(to right, rgb(1, 39, 50), rgb(1, 115, 146));
  padding: 5% 3%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  filter: drop-shadow(8px 8px 40px rgba(0, 0, 0, 0.35));
}

.cf_text {
  word-wrap: break-word;
  width: 50%;
  padding: 0 5%;
}

.cf_text1 {
  text-transform: capitalize;
  color: #32ffff;
  font-size: 40px;
  padding-bottom: 4%;
  word-wrap: normal;
}

.cf_text2 {
  font-size: 18px;
  color: white;
}

.cf_image {
  width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 auto;
  /* height: auto;   */
 
}

.cf_image iframe {
  width: 100%;
  margin: auto;
}

.cf_help {
  margin: 5% 10%;
  display: flex;
  flex-direction: row;
  padding: 2% 5%;
  text-transform: uppercase;
  justify-content:center;
}

.cf_helpText {
  display: flex;
  flex-direction: column;
  margin: 0 5% 0 5%;
  word-wrap: break-word;
  font-size: 30px;
}

.cf_button {
  color: white;
  filter: drop-shadow(8px 8px 30px rgba(0, 0, 0, 0.4));
  border-radius: 10px;
  font-size: 20px;
  padding: 5% 15%;
  margin-top: 5%;
  background-image: linear-gradient(to right, rgb(24, 1, 60), rgb(3, 91, 231));
  border: none;
  width: max-content;
  cursor: pointer;
}

.cf_helpImg > img {
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.cf_about {
  margin: 5%;
}

.cf_aboutHead {
  font-size: 40px;
  padding-bottom: 3%;
  background: linear-gradient(to right, rgb(59, 3, 148), rgb(203, 221, 250));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.cf_aboutContainer {
  display: flex;
  border: 5px solid #cad6e2;
  border-radius: 0 0 20px 20px;
  padding: 4%;
}

.cf_aboutText {
  word-wrap: break-word;
  font-size: 20px;
  width: 60%;
  padding-right: 5%;
}

.cf_aboutImg {
  width: 40%;
  justify-items: center;
}

.cf_aboutImg > img {
  display: block;
  margin-left: auto;
  width: 50%;
}

@media screen and (max-width: 850px) {
 .fund-intro-fund-box{
 width:10rem;
height:4rem;
border-radius: 8px;
}
.cf_help
{
    margin: 5% 7%;
}
}

@media screen and (max-width: 1020px) {
   .fund-intro-fund-box{
 width:8rem;
height:3rem;
margin-bottom:1rem; 
}
.fund-intro-amount{
font-size: 15px;
line-height: 13px;
}
.fund-intro-fund-text{
font-size: 10px;
line-height: 17px;  
}
  .cf_car {
    display: flex;
    align-items: center;
    flex-direction:column-reverse;
    padding: 7% 5%;
  }
  .cf_text {
    width: 100%;
    padding-bottom: 2%;
  }

  .cf_image {
    display: block;  
    /* margin-left: auto; */
       /* margin-top: auto; */
      /* margin-bottom: auto;  */
    /* height: auto; */
    width: 100%;
    /* height: auto; */
  }

  .cf_help {
    margin: 5% 0;
  }
  .cf_helpText {
    font-size: 20px;
    width: 70%;
  }

  .cf_button,
  .cf_aboutText {
    font-size: 15px;
  }

  .cf_aboutHead {
    font-size: 20px;
  }

  
}

@media screen and (max-width: 450px) {
  .fund-intro-amount{
font-size: 14px;
line-height: 12px;
}
.fund-intro-fund-text{
font-size: 9px;
line-height: 8px;  
}
.fund-intro-fund-box{
width:6rem;
height:3rem;
border-radius: 6px;
}
}
@media screen and (max-width: 425px) {
  .cf_text1{
    font-size: xx-large;
  }
  .cf_container{
    margin-left: 5%;
    margin-right: 5%;
  }
}