*{
    margin: 0;
    padding: 0;
}
.ttable-box
{   
    width: 100%;
    margin: 50px auto;
}
#decrement,#increment{
    width: 23px;
    height: 23px;
    margin:3px ;
    border: none;
    background: #C4C4C4;
}
.response-num-div{
   display: flex;
    flex-direction: row;
    gap:14px;
    justify-content: center;
    width: 70%;
    height: 59px;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    background: #EBEBEB;
    border-radius: 10px;
    padding: 5px;
    padding-left:20px;
}
.response-num{
    align-self: center;
}
.response-num-btn{
    align-self: center;
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: space-evenly;
}
.ttable-row{
    display: table;
    width: 70%;
    margin: 10px auto;
    font-family: sans-serif;
    background: transparent;
    padding: 12px 0;
    color: #555;
    font-size: 13px;
    box-shadow: 0 1px 4px 0px rgba(0,0,50,0.3);
}
.ttable-head{
    background: #2e37df;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
}
.ttable-head .ttable-cell{
    border-right: none;
}
.ttable-cell{
    display: table-cell;
    width: 20%;
    text-align: center;
    padding: 4px 0;
    border-right: 1px solid #d6d4d4;
    vertical-align: middle;
}
.ffirst-cell{
    text-align: left;
    padding-left: 10px;
}
.llast-cell{
    border-right: none;
}

@media only screen and (max-width: 400px) 
{
    .response-num-div{
        width:95%;
        font-size: 14px;
    }
    .ttable-row{
        width: 95%;
    }
    .response-num-btn{
        display: flex;
        flex-direction: column;
        align-content: center;
    }
    #decrement,#increment{
        width: 17px;
        height: 17px;
        margin:1px ;
    }
}

@media (min-width: 400px) and (max-width: 1024px) 
{
    .response-num-div{
        width:95%;
    }
    .ttable-row{
        width: 95%;
    }
}