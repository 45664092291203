.contri-card-border{
justify-content:space-between;
border: 1px solid #B9B9B9;
box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.23);
}

.contri-card-text{
    width:200px;
}

.contri-image{
  display: flex;
  justify-content:center;
  align-items:center;
  width: 50px;
  height: 50px;
  font-size:3rem;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}

.flex{
    display:flex;
}
.align-center{
    align-items:center;
}
.m-auto{
    margin:auto auto;
}

.linkedin_icon{
    font-size:1.5rem;
    visibility:hidden;
}

.justify-center{
    justify-content:center;
}
.flex-col{
    flex-direction:column;
}
/* card2 */
.top-contri-out{
    padding:2rem;
}
.top-contri-container{
    height:100%;
    width:100%;  
    max-height:265px;
    max-width:270px;   
}

.contri-image2{
    width:100px;
    height:100px;
    font-size:6rem;
}

.margin-top-1{
    margin-top:0.5rem;
}
.justify-end{
    justify-content:end;
}
.linkedin_icon_2{
    font-size:2rem;
    visibility:hidden;
}

@media (max-width:1000px) {
    .top-contri-out{
    padding:1rem;
}
.top-contri-container{
    width:200px;
    height:200px;
}
.contri-image2{
    width:70px;
    height:70px;
}
}

@media (max-width:770px) {
    .top-contri-out{
    padding:0.5rem;
}
.top-contri-container{
    width:180px;
    height:180px;
}
.contri-image2{
    font-size:4rem;
}
.linkedin_icon_2{
    font-size:1.3rem;
}
}
@media (max-width:600px) {
    .contri-image{
        height:30px;
        width:30px;
        font-size:2rem;
    }
    .linkedin_icon{
        font-size:1.5rem;
    }
}

@media (max-width:400px) {
    .contri-image{
        height:20px;
        width:20px;
        font-size:1.2rem;
    }
    .contri-card-text{
        width:150px;
    }
    .linkedin_icon{
        font-size:1.2rem;
    }
    .contri-card-border {
        font-size:0.7rem;
    }
}