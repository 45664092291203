@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
#clap_inside {
  display: flex;
  flex-direction: column;
  height: 150px;
  font-family: "Montserrat", sans-serif;
  position: sticky;
  justify-content: flex-end;
  align-items: center;
  top: 40%;
  margin-bottom: 15%;
}
#clap_bigbox {
  height: 250px;
  width: 200px;
  margin-right: -19px;
  border-radius: 13px;
  box-shadow: 0px 0px 20px;
}
#clap_first {
  margin-top: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#clap_first_shareimage {
  margin-left: 10px;
  margin-top: -10px;
}
#clap_first_sharetitle {
  font-weight: 900;
  width: 70%;
  font-size: 10px;
  margin-top: -5px;
  margin-left: 20px;
  text-align: left;
}
#clap_embed_shareimage {
  height: 35px;
  width: 35px;
  margin-top: 0px;
}
#clap_second {
  margin-top: 0px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
#clap_second_clapimage_black {
  display: flex;
  margin-left: 0px;
  cursor: pointer;
  height: 35px;
  width: 35px;
}
#clap_clap {
  margin-top: 8px;
  margin-left: 2px;
  font-size: 15px;
}
#clap_second_clapimage_black:hover {
  opacity: 0.9;
}
#clap_second_clapimage_white {
  display: flex;
  margin-left: -30px;
  height: 35px;
  width: 30px;
}
#clap_feed_data {
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
  width: 30px;
  height: 20px;
}

#clap_third {
  margin-top: 5px;
  display: flex;
  height: 30px;
  font-weight: 900;
  flex-direction: row;
  justify-content: space-around;
  font-size: 11.5px;
  font-weight: 700;
}
#clap_third:hover {
  cursor: pointer;
}
#clap_fourth {
  margin-top: 5px;
  display: flex;
  height: 140px;

  flex-direction: row;
  justify-content: space-around;
}
#clap_fourth_reportcontent {
  padding-top: 5px;
  font-weight: 500;
  border: 1px solid rgb(190, 189, 189);
  padding-left: 12px;
  width: 170px;
  height: 90px;
  font-size: 12px;
}
#clap_inside a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
@media (min-width: 0px) and (max-width: 767px) {
  #clap_bigbox {
    display: none;
  }
}
