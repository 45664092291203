.displayContactCard_contactCardDiv{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap ;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 15px;
}
.displayContactCard_firstbox{
    border-bottom: black 1.5px solid;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap ;
}
.displayContactCard_secondbox{
    border-bottom: black 1.5px solid;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap ;
}
.displayContactCard_thirdbox{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap ;
}
.displayContactCard_nameofapplicant{
    color: black;
    font-weight: bold;
}
.displayContactCard_reason{
    color: black;
}
.new{
    background-color: #F1A32E;
}
.progress{
    background-color: #F2CC45;
}
.done{
    background-color: #9EDA75;
}