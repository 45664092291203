#featurecard_bigbox {
  border: 2px solid rgb(245, 243, 243);
  box-shadow: 0px 0px 15px;
  position: sticky;
  top: 310px;
  display: flex;
  width: 240px;
  height: 255px;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}
#featurecard_title {
  font-size: 22px;
  display: flex;
  width: 150px;
  font-weight: 700;
  text-align: center;
}
#learnmore_button {
  font-size: 20px;
  margin-top: 50px;
  font-weight: 700;
  background-color: black;
  color: white;
  width: 130px;
  border-radius: 5px;
  height: 50px;
}
#learnmore_button:hover {
  cursor: pointer;
}
@media (min-width: 630px) and (max-width: 1200px) {
  #featurecard_bigbox {
    position: initial;
  }
}
@media (min-width: 0px) and (max-width: 630px) {
  #featurecard_bigbox {
    position: static;
    top: 0px;
  }
}
