*{
    margin: 0 0;
    padding: 0 0;
}
.AppQuill{
    width: 70%;
    display: block;
    margin: auto;
}
#quillTitle{
    margin: 25px 25px;
    font-size: large;
    font-weight: bold;  
    width: fit-content;
    padding: 5px 5px 5px 5px;
    border: 1px solid black;
    border-radius: 4px;
}
#quillauthor{
    margin: 25px 25px;
    font-size: large;
    font-weight: bold;  
    width: fit-content;
    padding: 5px 5px 5px 5px;
    border: 1px solid black;
    border-radius: 4px;
}
#quillFile{
    cursor: pointer;
    margin-left: 10px;
    font-weight: bold;  
    width: fit-content;
    padding: 5px 5px 5px 5px;
    border: 1px solid black;
    border-radius: 4px;
}
.coverPic{
    display: flex;
    flex-direction: row;
}
#coverlabel{
    font-weight: bold;
    display: flex;
    margin-left: 25px;

}
::placeholder{
    color: black;
}
input:focus{
   outline: none;
}
.editor{
    background-color: rgb(214, 213, 213);
    margin: 25px 25px;
    padding: 5px 5px;
    padding-bottom: 12px;   
}
.ql-toolbar {
    background-color: rgb(243, 243, 243);
    border-radius: 5px ;
}
.ql-container{
    background-color: white;
    border-radius: 5px ;
    height: 800px;
}
.ql-editor{
    background: #FFFDFD;
}

.ql-bubble{   
   height: auto;
}
.ql-hidden{
    display: none;
}
.preview_heading{
    margin: 25px 25px;
    margin-bottom: 0px;
    font-size: 18px;
}
.blog_preview{
    border: 1px solid #000000;
    background: #FFFDFD;
    padding: 20px 27px;
    height: auto;
    margin: 25px 25px;
    margin-top: 0px;
   
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin: 70px 55px 50px 55px;

}
.btn-black{
    padding: 10px 30px;
    font-size: large;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor:pointer;
}
.btn-red{
    padding: 10px 20px;
    font-size: large;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor:pointer;
}
.btn-green{
    padding: 10px 25px;
    font-size: large;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor:pointer;
}
.btn-green:active {
    background-color: #3e8e41;
    transform: translateY(1px);
}
.btn-red:active {
    background-color: red;
    transform: translateY(1px);
}
.btn-black:active {
    background-color: black;
    transform: translateY(1px);
}

@media screen and (max-width:768px){
    .AppQuill{
        width: 90%;
        display: block;
        margin: auto;
    }
    .buttons{
        width: 50%;
        display: block;
        margin: auto;
    }
    .btn{
        width: 100%;
        margin-bottom: 10px;
    }

    
}