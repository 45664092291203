*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.PageHeader-container{
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 1em;
}