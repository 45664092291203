*{
    margin: 0;
    padding: 0;
}

.ContactUs_whole-body {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: space-between;
}

.ContactUs_details {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 45vw;
    padding: 20px;
    margin-left: 120px;
}

.ContactUs_tt {
    margin-left: 130px;
}

.ContactUs_abc iframe {
    margin-top: 60px;
    margin-left: 26px;
    border: none;
}

#ContactUs_space {
    margin-top: -65px;
}

.ContactUs_container {
    margin-right: auto;
    margin-left: auto;
    box-shadow: 10px 10px 4px 0px #00000040;
    border: 1px solid #00000021;
    border-radius: 10px;
    width: 500px;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 2vh;
    font-size: 1.2rem;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
}

.ContactUs_field {
    position: relative;
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.ContactUs_container .ContactUs_field span {
    position: absolute;
    left: 0%;
    padding: 5px 0;
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}

.ContactUs_container input,
.ContactUs_container textarea {
    width: 95%;
    padding: 5px 0;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: 1.5px solid black;
}

.ContactUs_whole-body .ContactUs_container .ContactUs_field input:focus~span,
.ContactUs_whole-body .ContactUs_container .ContactUs_field input:valid~span,
.ContactUs_whole-body .ContactUs_container .ContactUs_field textarea:focus~span,
.ContactUs_whole-body .ContactUs_container .ContactUs_field textarea:valid~span {
    color: black;
    font-size: 12px;
    transform: translateX(-20px);
    transform: translateY(-20px);
}

.ContactUs_heading {
    text-align: center;
    padding-top: 50px;
}

.ContactUs_button {
    text-align: center;
}

#contact_button {
    border-radius: 15px;
    background: #E9910DFC;
    height: 40px;
    width: 100px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: medium;
}

.ContactUs_abc {
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.ContactUs_icon {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    padding-top: 8px;
}

.fa {
    font-size: 30px;
    margin-top: 5px;
}

.ContactUs_iconimgnx {
    height: 40px;
    width: 40px;
    margin-left: -5px;
}

.ContactUs_iconimgacc {
    height: 40px;
    width: 40px;
    margin-left: -15px;
}
.ContactUs_phone{ margin-top: 4px;
    font-size: xx-large;
}

.ContactUs_tt {
    font-weight: bold;
    position: absolute;
    left: 160px;
    padding-top: 10px;
}

#ContactUs_s1,
#ContactUs_s2 {
    background: #C4C4C4BD;
    width: 90%;
    height: 65%;
    padding: 0.8%;
    border-radius: 5px;
}

.ContactUs_style {
    color: rgb(109, 109, 255);
    font-weight: bolder;
}

@media screen and (max-width:938px) {
    .ContactUs_whole-body {
        display: flex;
        flex-direction: column-reverse;
    }
    #ContactUs_abcd {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    #ContactUs_space {
        margin-top: -15px;
    }
    iframe {
        margin-top: 20px;
    }
    .ContactUs_tt {
        margin-left: 170px;
    }
    .ContactUs_details {
        margin-left: 160px;
        /* margin-right: auto; */
    }
}

@media screen and (min-width:938px) {
    #ContactUs_abcd {
        margin-bottom: 90px;
    }
}

@media screen and (min-width:425px) and (max-width:768px) {
    .ContactUs_container {
        width: 400px;
    }
    .ContactUs_tt {
        margin-left: 90px;
    }
    .ContactUs_details {
        margin-left: 80px;
    }
}

@media screen and (min-width: 425px) and (max-width: 583px) {
    .ContactUs_tt {
        margin-left: 20px;
    }
    .ContactUs_details {
        margin-left: 10px;
    }
}

@media screen and (max-width:425px) {
    .ContactUs_container {
        width: 95%;
        padding-left: 7px;
        margin-left: 10px;
    }
    .ContactUs_container input,
    .ContactUs_container textarea {
        width: 98%;
    }
    .ContactUs_tt {
        position: absolute;
        left: 72px;
        padding-top: 10px;
    }
    .ContactUs_tt {
        margin-left: 60px;
    }
    .ContactUs_details {
        margin-left: 50px;
    }
    .ContactUs_abc {
        margin-left: 0px;
        display: flex;
        flex-direction: row;
    }
    iframe {
        width: 100%;
    }
}