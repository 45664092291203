.reg-details
{
    display: flex;
    flex-direction: column;
}
.pay-btn{
    width: 100px;
    padding:10px;
    color: white;
    background-color: #3c4fe0;
    border: 0ch;
    border-radius: 50px;
    cursor: pointer;
}
.reg-details-heading{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.reg-details-h1{
    margin-top: 10px;
    text-align: center;
}
.reg-details-table{
    width: 60%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 49px;
    border-collapse: collapse;
    border: 1px solid black;
}
.reg-details-table td,th{
    text-align: center;
    padding: 10px;
    border: 1px solid black;
}

.reg-details-table th{
  background-color: #3c4fe0;
  color: #fff;
}

@media (max-width: 460px){
    .reg-details-heading{
        flex-direction:column;
    }
   .reg-details{
    margin:3vw;
    align-items: center;
    padding: 2px;
   }
   .reg-details td,th{

    padding: 3px;
    font-size: 12px;

}
   .reg-details-table{
    position: relative;
   }
}