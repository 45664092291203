.galleryContainer2{
  background-color: black;
}
.galleryContainer {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  padding-top: 20px;
  padding-bottom: 20px;
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-column: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0, 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 8px;
}

.Galleryimg {
  border-radius: 10px;
}
.gallery .pics:hover {
  filter: opacity(0.8);
}
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-column: 2;
  }
}
@media (max-width: 385px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-column: 3;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
  }

  .gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .Galleryimg {
    border-radius: 5px;
  }
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model .open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffff;
  cursor: pointer;
}
#nitrox-hide{
  display: none;
}
#nitrox-hide-btn{
      cursor: pointer;
    border: none;
    font-size: 25px;
    height: 40px;
    margin: auto;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    /* transform: translateY(10px); */
    width: 215px;
    animation: rotate 1s linear infinite;
  }

  /* Here we define our animation with the name "rotate" */
  @keyframes rotate {
    from {
      transform: translateY(0px);
    }
    
    to {
      transform: translateY(20px);
    }
  }
  #viewless-btn{
    cursor: pointer;
    text-align: center;
    margin: auto;
    padding-bottom: 15px;
  }