.about_autokriti-container {
  width: 89%;
  height: auto;
  padding: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px auto;
  box-shadow: 10px 10px 15px -3px grey;
}

.about_autokriti-left {
  padding: 4em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.about_autokrit-left-heading {
  color: black;
  margin-bottom: 20px;
  font-size: 30px;
  width: 100%;
  position: relative;
}

.about_autokrit-left-subheading {
  background-image: linear-gradient(to right, #74ebd5 0%, #9face6 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 1000;
}

.about_autokriti-left button {
  height: 5vh;
  width: auto;
  font-size: 20px;
  color: #fff;
  padding: 1rem 3rem;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightcoral;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.register {
  height: 5vh;
  width: auto;
  font-size: 20px;
  color: #fff;
  padding: 1rem 3rem;
  /* margin-top: 4em; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightcoral;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.about_autokriti-right {
  /* border: solid 1px #000; */
  padding: 2em;
  padding-right: 4em;
  display: flex;
  width: 300%;
  height: 80%;
  margin-top: 5%;
  /* justify-content: center; */
  align-items: center;
}

.about_autokriti-right img {
  width: 100%;
}
.bigbox {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.smallbox {
  background-color: #95afc0;
  /* background-color: #686de0; */
  cursor: pointer;
  border: none;
  text-align: center;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  height: 200px;
}
.smallbox img {
  width: 80px;
  height: 80px;
  transition: transform 0.2s;
}
.smallbox:hover img {
  transform: scale(1.2);
}
.smallbox h3 {
  padding-top: 30px;
}

@media screen and (max-width: 879px) {
  .about_autokriti-container {
    flex-wrap: wrap-reverse;
    padding: 1em;
  }
  .about_autokriti-right img {
    height: 100%;
    width: 100%;
  }
  .about_autokriti-left {
    padding: 1em;
    font-size: 16px;
  }
  .about_autokriti-right {
    padding-right: 2em;
  }
}

@media screen and (max-width: 1193px) and (min-width: 1112px) {
  .about_autokriti-right img {
    max-height: 60%;
    width: 100%;
  }
}

@media screen and (max-width: 1193px) and (min-width: 880px) {
  .about_autokriti-right img {
    max-height: 50%;
    width: 110%;
  }
}

.toTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
@media screen and (max-width: 666px) {
  .smallbox {
    width: 160px;
    height: 160px;
    margin: 20px;
  }
  */ .smallbox img {
    width: 30px;
    height: 30px;
  }
  .smallbox h3 {
    font-size: 12px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 486px) {
  .smallbox {
    width: 120px;
    height: 140px;
  }
  .smallbox img {
    width: 80px;
    height: 80px;
  }
  .smallbox h3 {
    font-size: 12px;
    padding-top: 2px;
  }
}

@media screen and (max-width: 396px) {
  .smallbox {
    width: 85px;
    height: 85px;
    margin: 10px 10px 40px 10px;
    padding: 5px;
  }

  .smallbox h3 {
    font-size: 12px;
    padding-top: 20px;
  }
    .smallbox img {
    width: 60px;
    height: 60px;
  }
}
