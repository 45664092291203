@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.testimonial {
  width: 70%;
  margin: 2rem auto;
  height: 370px;
}
.testimonial-title{
  margin-top:40px;
  margin-bottom: 50px;
  padding:10px;
  font-size: 25px;
  font-weight: bold;
  color:white;
  background-color: #24292E;
  width:fit-content;
}
.testimonial_slide img {
  width: 100%;
  margin: 0 auto;
}

.testimonial_slide {
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
}

.testimonial_activeSlide {
  transform: scale(1.3);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
.card1,.card2,.card3{
  border: 1px solid black;
}

.arrow svg {
  
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 3%;
  top: 50%;
}

.prev {
  left: 3%;
  top: 50%;
}

@media only screen and (max-width:1024px){
  .testimonial{
    width:95%;
  }
}