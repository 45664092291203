*{
    margin: 0;
    padding: 0;
}
.ttable-box
{   
    width: 75%;
    margin: 50px auto;
}
#decrement,#increment{
    width: 23px;
    height: 23px;
    margin:3px ;
    border: none;
    background: #C4C4C4;
}
.response-num-div{  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:14px;
    align-self: center;
    width: 60%;
    height: 59px;
    margin-left: 20%;
    align-content: center;
    background: #EBEBEB;
    border-radius: 10px;
    padding: 5px;
}
.response-num{
    align-self: center;
}
.response-num-btn{
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 20%;
    justify-content: space-evenly;
}
.totalAmbassadorResponses{
    align-self: center;
}
.ttable-row{
    display: table;
    width: 80%;
    margin: 10px auto;
    font-family: sans-serif;
    background: transparent;
    padding: 12px 0;
    color: #555;
    font-size: 13px;
    box-shadow: 0 1px 4px 0px rgba(0,0,50,0.3);
}
.ttable-head{
    background: #2e37df;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
}
.ttable-head .ttable-cell{
    border-right: none;
}
.ttable-cell{
    display: table-cell;
    width: 20%;
    text-align: center;
    padding: 4px 0;
    border-right: 1px solid #d6d4d4;
    vertical-align: middle;
}
.ffirst-cell{
    text-align: left;
    padding-left: 10px;
}
.llast-cell{
    border-right: none;
}


@media only screen and (max-width: 600px) {
  .ttable-row {
    font-size: 11px;
  }
}



@media only screen and (max-width: 500px) 
{
    #ambassador-backBtn{
        width: 110.06px;
        height: 49px;
        left: 32px;
    
        color: white;
        background: #000000;
        border-radius: 10px;
    }
    #amb-signout{
        
        width: 121.06px;
        height: 49px;
        float: right;
        background: #F4970A;
        border: none;
        border-radius: 10px;
    }
    .response-num-btn{
        display: flex;
        flex-direction: column;
        align-content: center;
    }
    #decrement,#increment{
  
        width: 17px;
        height: 17px;
        margin:1px ;
  
        }

        .response-num-div{
            display:flex;
            
            gap:8px;
            font-size:10px;
            margin-left:50px;
        }

       

      
}


@media only screen and (max-width: 320px) 
{
    #ambassador-backBtn{
        width: 110.06px;
        height: 49px;
        left: 32px;
    
        color: white;
        background: #000000;
        border-radius: 10px;
    }
    #amb-signout{
        
        width: 121.06px;
        height: 49px;
        float: right;
        background: #F4970A;
        border: none;
        border-radius: 10px;
    }
    .response-num-btn{
        display: flex;
        flex-direction: column;
        align-content: center;
    }
    #decrement,#increment{
  
        width: 17px;
        height: 17px;
        margin:1px ;
  
        }

        .response-num-div{
            display:flex;
           
            gap:15px;
            font-size:10px;
            margin-left:45px;
        }

     
}

