@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
#clapmobile_inside {
  display: flex;
  height: 45px;
  box-shadow: 2px 2px 5px #aaaaaa;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-end;
  align-items: center;
  margin-left: 6px;
  font-size: 15px;
  margin-right: 6px;
  width: 100%;
  border-radius: 4px;
}
#clapmobile_bigbox {
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
}
#clapmobile_first {
  display: flex;
  flex-direction: row;
}
#clapmobile_second {
  display: flex;
  flex-direction: row;
}
#clapmobile_embed_clapimage_white {
  margin-top: 4px;
  padding-right: 9px;
  height: 20px;
  width: 40px;
}
#clapmobile_first_frequency {
  padding-top: 6px;
}
#clapmobile_embed_clapimage_black {
  margin-top: 4px;
  padding-right: 6px;
  height: 22px;
  width: 40px;
}
#clapmobile_embed_clapimage_black:hover {
  cursor: pointer;
  opacity: 0.9;
}
#clapmobile_second_claptext {
  padding-top: 6px;
}
#clapmobile_embed_shareimage {
  padding-left: 8px;
  width: 35px;
  height: 30px;
}
#clapmobile_embed_shareimage:hover {
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1440px) {
  #clapmobile_bigbox {
    display: none;
  }
}
