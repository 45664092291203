/* input,
textArea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 2px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 10px;
} */
#check {
  width: 50px;
}
.texti{
  margin-right: 2px;
}
.textt{
  padding-top: 9px;
}
.check-Field {
  display: flex;
  justify-content: stretch;
  /* background-color: red; */

}
.FundingForm {
  width: 70%;
  margin-left: auto;
  padding: 20px 20px;
  margin-right: auto;
  box-shadow: 10px 10px 15px -3px grey;
  margin-bottom: 70px;
}
.msg {
  margin: 10px;
}
.Field {
  display: flex;
  /* flex-direction: column; */
}
.pay {
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.sampleAmountDiv{
  display: flex;
  justify-content:space-evenly;
  flex-wrap: wrap;
  margin: 0 10px 0 10px;
  padding: 0 20px 0 20px;
}
.sampleAmount{
  border: 2px solid rgb(41, 129, 41);
  border-radius: 5px;
  padding: .4%;
  /* margin: 0 5% 0 0; */
  cursor: pointer;
}
#bank_details{
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 2px solid rgb(41, 129, 41);
  border-radius: 5px;
  box-sizing: border-box;
  margin: 15px;
}
.QrCode{
  height: 20%;
  width: 50%;

}
@media (min-width: 320px) and (max-width: 620px) {
  .Field {
    display: flex;
    flex-direction: column;
  }
  .sampleAmount{
    margin-top: 2%;
  }
  .FundingForm{
    width: 90%;
  }
}
