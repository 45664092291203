.team-achievements_container{
    height: 100vh;
    width: 85%;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-achievements_container h1{
    font-size: 1.2em;
}

.team-achievements_heading{
    height: 10%;
    width: 100%;
    /* border: 1px solid #000; */
    font-size: 2.5em;
    display: flex;
    align-items: center;
    padding-top: 1em;
    font-weight: 500;
}

/* ------------------------ */

.team-achievements_box{
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

    /* ------------------------ */

.team-achievements_team-box{
    width: 45%;
    height: 80%;
    border: solid 4px #000;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: -7px 7px 4px #9c0c0c;
}

        /* ---------------------------------- */
.team-achievements_team-boxHeader{
    height: 15%;
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0.1em 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}

            /* ------------------------ */

.team-achievements_team-boxHeaderIcon{
    height: 95%;
    width: 17%;
    /* border: solid 1px #000; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-achievements_team-boxHeaderIcon img{
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    padding: 0.1em;
    position: relative;
    vertical-align: middle;
}

            /* ------------------------ */

.team-achievements_team-boxHeaderName{
    height: 100%;
    width: 80%;
    display: flex;
    padding: 1em;
    align-items: center;
    font-size: 1.4em;
}

        /* ------------------------ */

.team-achievements_team-boxContent{
    height: 80%;
    width: 100%;
    /* border: solid 1px #000; */
    padding: 0.5em;
    overflow: scroll;
}

            /* ------------------------ */

.team-achievements_team-boxContentRank{
    min-height: 15%;
    width: 100%;
    border-bottom: solid 1px grey;
    font-size: 15px;
    margin-bottom: 0.5em;
}

.team-achievements_team-boxContentRank p{
    color: rgb(47, 45, 45);
    font-size: 16px;
    font-weight: bold;
}

/* ---------------------------------- */

@media only screen and (max-width: 900px) {
    .team-achievements_container{
        height: auto;
    }

    .team-achievements_box{
        flex-direction: column;

    }
    .team-achievements_team-box{
        width: 80%;
        margin-bottom: 2em;
        max-height: 100vh;
    }
}

@media only screen and (max-width: 500px) {
    .team-achievements_team-box{
        width: 95%;
    }
}
@media screen and (max-width: 680px) {
    .TeamAboutUs-heading{
        font-size:30px;
    }
}