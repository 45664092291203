.progress_bar_container{
  height: 21px;
  width: 70%;
  background-color: rgb(3, 91, 231);
  border-radius: 50px;
  margin-left: 15%;
  margin-right: 15%;
 

.slider{
  height: 100%;
  border-radius: inherit;
  text-align: right;
}
.text{
     padding: 5px;
    color: black;
    font-weight: bold;
    text-align: center;  
  }

}