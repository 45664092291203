*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.AutokritiCard-Container{
    width: 70%;
    height: auto;
    margin: 0 auto;

    background-color: rgb(248 249 249);

    box-shadow: 0px 0px 10px -3px grey;
    border-radius: 5px;
    margin-bottom: 3em;
    padding: 1em;

    display: flex;
    flex-direction: column;
}

.AutokritiCard-Intro{
    line-height: 1.5em;
    font-size: 0.8em;
}

.AutokritiCard-Body{
    display: flex;
    flex-direction: row;
    margin-top: 1.5em;
}

.AutokritiCard-Icons{
    width: 50%;
    display: flex;
}

.Autokirtee12_content_list {
    margin-top: 8%;
    font-size: large;
    font-weight: 600;
    display: flex;
    gap: 5%;
}

.AutokritiCard-Poster{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AutokritiCard-Poster img{
    position: relative;

}


/*-------DropDown-----*/

.Autokirtee12_dropdowns {
    display: flex;
    flex-direction: row;
    font-size: medium;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2%;
    justify-content: center;
}

.Autokirtee_12_dropdown {
    background-color: #5dbea3;
    cursor: pointer;
    height: auto;
    line-height: 2em;
    font-size: 16px;
    padding: 0.3em 0;
    color: white;
    border-radius: 2px;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    width: 200px;
    vertical-align: middle;
}

.Autokirtee_12_icons {
    line-height: 36px;
    padding-left: 2px;
}

.Autokirtee_12_dropdown-content {
    display: none;
    border-radius: 5px;
    background-color: #5dbea3;
    position: absolute;
    z-index: 1;
    min-width: 200px;
    max-width: 200px;
    margin-top: 50px;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.Autokirtee_12_dropdown-content1 {
    width: 190px;
    height: max-content;
    overflow-wrap: break-word;
    padding: 5px;
    /* white-space: wrap; */
    background-color: whitesmoke;
    color: black;
}

.Autokirtee_12_dropdown:hover .Autokirtee_12_dropdown-content {
    display: block;
}

.AutokritiCard-Buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
    margin-top: 1em;
}

.Autokirti_12_button{
    padding: 1em;
    background-color: orange;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1175px){
    .AutokritiCard-Body{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .AutokritiCard-Poster{
        width: 80%;
    }

    .AutokritiCard-Icons{
        margin-top: 1em;
        width: 100%;
        justify-content: space-around;
        align-items: flex-end;

    }

    .Autokirtee12_content_list {
        display: flex;
        flex-direction: row;
        font-size: small;
        font-weight: 600;
    }
    .AutokritiCard-Container{
        width: 80%;
    }
}

@media screen and (max-width: 600px){
    .AutokritiCard-Container{
        width: 90%;
    }
}

@media screen and (max-width: 700px){
    .AutokritiCard-Buttons{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 7em;
    }

}
