* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homepage__div{
  position: relative;
  display: flex;
  justify-content: center;
}

.homepage__div p{
  display: block;
} 

.homepage_div__content{
  display: flex;
  position: absolute;
  top: 14%;
  width: 100%;
  color: white;
  font-size: 52px;
  font-weight: 600;
  line-height: 1.2;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
}
.FirstDisplayDIV-main-container {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

.FirstDisplayDIV-main-container p {
  display: none;
  padding-top: 10%;
}

.FirstDisplayDIV-main-container img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1130px){
  .homepage_div__content{
    font-size: 47px;
  }
}

@media (max-width: 960px){
  .homepage_div__content{
    font-size: 42px;
  }
}
@media screen and (max-width: 775px) {
  .FirstDisplayDIV-main-container {
    height: 50vh;
  }
  .homepage_div__content{
    font-size: 38px;
  }
}

@media screen and (max-width: 750px) {
  .FirstDisplayDIV-main-container {
    height: 40vh;
  }
}
@media (max-width: 680px){
  .homepage_div__content{
    font-size: 32px;
    top: 6%;
  }
}
@media screen and (max-width: 650px) {
  .FirstDisplayDIV-main-container img {
    display: block;
  }

  .FirstDisplayDIV-main-container p {
    display: none;
  }

  .FirstDisplayDIV-main-container {
    background: #000;
    color: #fff;
    text-align: left;
    font-size: 45px;
  }
}

@media (max-width: 565px){
  .homepage_div__content{
    font-size: 27px;
  }
}
@media (max-width: 480px){
  .homepage__div {
    height: 30vh;
  }
  .homepage_div__content{
    width: 60%;
    text-align: center;
    font-size: 22px;
    top: 4%;
    gap: 5px;
  }
  .homepage_div__content span:nth-child(2){
    font-size: 17px;
    font-weight: 500;
  }
}
@media screen and (max-width: 400px) {
  .FirstDisplayDIV-main-container p {
    font-size: 35px;
  }
}

@media (max-width:300px){
  .homepage_div__content{
    width: 70%;
  }
}

@media (min-width: 535px) and (max-width: 650px) {
  .FirstDisplayDIV-main-container {
    height: 50vh;
  }
}
@media (min-width: 650px) and (max-width: 775px) {
  .FirstDisplayDIV-main-container {
    height: 60vh;
  }
}
