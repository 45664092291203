@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
#become_sponsor_bigbox {
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  background-color: #efefef;
  flex-direction: row;
  justify-content: center;
}
#become_sponsor_title {
  width: 600px;
  display: flex;
  flex-direction: column;
  font-family: Copperplate Gothic Bold;
  justify-content: space-evenly;
}
#knowmore_button {
  height: 40px;
  background-color: #eed600;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  color: black;
  font-size: 18px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 160px;
}
#knowmore_button:hover {
  cursor: pointer;
  opacity: 0.8;
}
#spbutton {
  margin-left: 20px;
  margin-top: -30px;
}
#sponsor_content {
  font-size: 95px;
  margin-left: 20px;
  color: rgb(85, 83, 83);
  text-shadow: 0px 5px 10px;
}
#become_sponsor_image {
  width: 600px;
  display: flex;
  justify-content: flex-end;
}
#splogo {
  width: 450px;
}

@media (min-width: 0px) and (max-width: 375px) {
  #become_sponsor_image {
    display: none;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 50px;
  }
  #knowmore_button {
    margin-top: 20px;
  }
}
@media (min-width: 375px) and (max-width: 426px) {
  #become_sponsor_image {
    display: none;
  }
  #knowmore_button {
    margin-top: 20px;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 55px;
  }
}
@media (min-width: 426px) and (max-width: 540px) {
  #become_sponsor_image {
    display: none;
  }
  #knowmore_button {
    margin-top: 20px;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 55px;
  }
}
@media (min-width: 540px) and (max-width: 768px) {
  #become_sponsor_image {
    display: none;
  }
  #knowmore_button {
    margin-top: 20px;

    font-size: 22px;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 77px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #become_sponsor_image {
    width: 100px;
    display: flex;
  }
  #knowmore_button {
    margin-top: 20px;
    font-size: 22px;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 55px;
    margin-left: 2px;
  }
  #splogo {
    width: 300px;
  }
}

@media (min-width: 1024px) and (max-width: 2580px) {
  #become_sponsor_image {
    width: 410px;
    display: flex;
    margin-right: 0px;
  }
  #knowmore_button {
    margin-top: 20px;
    margin-left: 25px;
    font-size: 22px;
    padding-left: 10px;
  }
  #sponsor_content {
    margin-top: 50px;
    font-size: 90px;
    margin-left: 30px;
  }
  #splogo {
    width: 350px;
  }
}
