  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,100&display=swap');
#acc_container
{
    /* border: 2px solid black; */
    height: max-content;
    display: flex;
    width:70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 70px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
      z-index: 1;
     transform: rotateY(180deg);  
}


#acc_insidecontainer
{
    height:max-content;
    background-color: rgb(248 249 249);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.acc_component
{
    /* border: 2px solid black; */
    margin-left: 20px;
    margin-right: 20px;
    /* height: 25%; */
}
#acc_second
{
    /* background-color: #E8FCFD; */
    border-radius: 12px;
    height:max-content;
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-weight:500 ;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 3%;
    margin-right: 3%;
    box-shadow: 5px 5px 15px grey;
}
#acc_first
{
    height:max-content;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
#acc_third
{
    /* border: 2px solid black; */
    height: max-content;
    margin-bottom: 10px;
    margin-top: 10px;
    /* padding-right: 200px; */
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   flex-wrap: wrap;
    
}
#acc_card_container
{
    /* border: 2px solid black; */
    width: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
#acc_fourth
{
    background-color: #86d5da;

    box-shadow: 5px 5px 15px grey;
    /* background-color: #E8FCFD; */
    border-radius: 12px;
    height:max-content;
    font-family: 'Montserrat', sans-serif;
    /* font-style: italic; */
    font-weight:500 ;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 15px;
}

.AutokritiCheckOurEvents-heading {
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: black;
  margin-bottom: 8px;
  font-size: 30px;
  }
  .AutokritiCheckOurEvents-subheading {
    background-image: linear-gradient(to right, #74ebd5 0%, #9face6 100%);
    background-size: 100%;
    background-repeat: repeat;
    font-family: 'Montserrat', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-size: 40px;
    font-weight: 1000;
  }
  @media (min-width:300px) and (max-width:530px)
{
    #acc_container
    {
        width:95%;
        margin-left: auto;
        margin-right: auto;
        height: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    #acc_first
{
   
    padding-left: 0px;
   
}
.AutokritiCheckOurEvents-subheading {
  
    font-size: 24px;
  
  }
  .AutokritiCheckOurEvents-heading {
    
  font-size: 19px;
  }
  #acc_second,#acc_fourth
{
    
    font-size: 14px;
   
}
#acc_card_container {
    width: 100%;
}
}

@media (min-width:530px) and (max-width:1024px){
    #acc_container
    {
        width:95%;
        margin-left: auto;
        margin-right: auto;
        height: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}
