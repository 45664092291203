#card_container
{
    margin-top: 50px;
    /* border: 2px solid black; */
    height: 350px;
    display: flex;
    flex-direction: column;
    
    margin-bottom: 50px;
    width: 280px;
    display: flex;
    
}
#card_container  img{
transition: all 0.5s;
}
#card_container:hover #card-image img ,
#card_container:focus #card-image img  {
  transform: scale(1.1);
}
#shareincard_image:hover img ,
#shareincard_image:focus img  {
  transform: scale(1.1);
}
#card_date_image:hover img ,
#card_date_image:focus img  {
  transform: scale(1.1);
}
#card-image,#card-title,#card-actions
{
    border: none;
    box-shadow: 0px 0px 20px grey;
}
#card-image
{
    /* border-bottom:2px solid black ; */
    height: 55%;
    overflow: hidden;
}
#card-title
{
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    height: 45%;
}
#card-actions
{
    /* background-color:rgba(0, 0, 0, 0.219); */
    /* border-top:2px solid black ; */
    background-color: rgb(240, 232, 232);
    height: 10%;
}
#daq_blog_image
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#card-actions
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#shareincard
{
    display: flex;
    flex-direction: row;
}
#shareincard_image
{
    display: flex;
    align-items: center;
    margin-left: 10px;
    /* border: 2px solid black; */
}
#shareincard_title
{
    display: flex;
    align-items: center;
    /* border: 2px solid black; */
    /* color: rgb(104, 98, 98); */
    
}
#blog_embed_shareimage
{
    height: 25px;
    width: 25px;
    margin-right: 5px;
}
#card_date
{
    display: flex;
    align-items: center;
    /* border: 2px solid black; */
    margin-right: 10px;
}
#card_date_title
{
    margin-left: 5px;
}
#blog_embed_calender
{
    height: 20px;
}