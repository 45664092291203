.container-slider {
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

#autokriti_heading {
  display: flex;
  justify-content: center;
  top: 60%;
  position: relative;
  font-size: 76px;
}
#auto_image {
  opacity: 1;
  height: 140px;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  z-index: 5;
  border-radius: 50%;
  opacity: 0.3;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #949292;
}
.dot.active {
  background: rgb(32, 32, 32);
}

@media (min-width: 319px) and (max-width: 375px) {
  #auto_image {
    height: 40px;
  }
  .container-slider {
    height: 40vh;
  }
  .btn-slide {
    width: 24px;
    height: 24px;
    z-index: 3;
  }
  .btn-slide img {
    width: 11px;
    height: 17px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  #auto_image {
    height: 50px;
  }
  .container-slider {
    height: 40vh;
  }
  .btn-slide {
    width: 30px;
    height: 30px;

    z-index: 3;
  }
  .btn-slide img {
    width: 15px;
    height: 15px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 425px) and (max-width: 560px) {
  #auto_image {
    height: 50px;
  }
  .container-slider {
    height: 40vh;
  }
  .btn-slide {
    width: 33px;
    height: 33px;
    z-index: 3;
  }
  .btn-slide img {
    width: 15px;
    height: 17px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 560px) and (max-width: 691px) {
  #auto_image {
    height: 60px;
  }
  .container-slider {
    height: 60vh;
  }
  .btn-slide {
    width: 33px;
    height: 33px;
    z-index: 3;
  }
  .btn-slide img {
    width: 15px;
    height: 17px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 691px) and (max-width: 769px) {
  #auto_image {
    height: 70px;
  }
  .container-slider {
    height: 60vh;
  }
  .btn-slide {
    width: 33px;
    height: 33px;
  }
  .btn-slide img {
    width: 15px;
    height: 17px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 769px) and (max-width: 950px) {
  #autokriti_heading {
    top: 230px;
  }
  #auto_image {
    height: 70px;
  }
  .container-slider {
    height: 443px;
  }
  .btn-slide {
    width: 33px;
    height: 33px;
  }
  .btn-slide img {
    width: 15px;
    height: 17px;
  }
  .dot {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 1370px) and (max-width: 1500px) {
  #auto_image {
    height: 150px;
  }
  .container-slider {
    height: 826px;
  }
}
