@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
.crowdfunding__form__outercontainer{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.QRCode__div{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  gap: 10px;
}
.QRCode__div p{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.QRCode__div img{
  width: 70%;
  margin-bottom: 10px;
}
.funding-container{
  background: #fff;
  width: 90%;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  margin: 40px 0 10px 0px;
}
.funding-form-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.funding-container .text{
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: -webkit-linear-gradient(right, #56d8e4, teal, #56d8e4, teal);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.funding-container form{
  padding: 30px 0 0 0;
}
.funding-container form .form-row{
  display: flex;
  margin: 32px 0;
}

.funding-container form .upload__description{
  margin: 0 !important;
}
.funding-container form .upload__description p{
  color: #666;
  font-size: 15px;
}
form .form-row .input-data .upload__photo-label{
  position: relative;
  bottom: 5px;
}
form .form-row .input-data{
  width: 95%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea{
  height: 70px;
}
.input-data input,
.input-data select,
.textarea textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea label.active{
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
}

.textarea textarea{
  resize: none;
  padding-top: 10px;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea label.active .underline:before{
  transform: scale(1);
}
.submit-btn .input-data{
  /* margin-top: 1.5rem !important; */
  overflow: hidden;
  height: 45px!important;
  width: 186px !important;
}

.another-btn .input-data{
  /* margin-top: 1.5rem !important; */
  overflow: hidden;
  height: 45px!important;
  width: 186px !important;
}

.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #56d8e4, teal, #56d8e4, teal);
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}
.another-btn .input-data button{
  display: none;
  height: 45px;
  width: 186px;
  background: -webkit-linear-gradient(right, #56d8e4, teal, #56d8e4, teal);
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.account__details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.copy__button{
  width: max-content;
  height: max-content;
  padding: 5px;
  border: 0;
  background-color: white;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
/* .copy__button:hover{
  border: 1px solid black;
} */
.account__details p{
  font-weight: 500;
  font-size: 22px;
}
@media (max-width: 1378px){
  .QRCode__div{
    width: 30%;
  }
}

@media (max-width: 1233px){
  /* .crowdfunding__form__outercontainer{

  } */
  .funding-form-div{
    justify-content: center;
  }
   .QRCode__div{
    width: 80%;
    margin-bottom: 40px;
   }
   .QRCode__div img{
    width: 40%;
   }
   .funding-container{
    margin: 40px auto 40px;
   }
   .funding-container form .submit-btn{
    display: none;
   }
   .submit-btn{
    display: none;
   }

   .another-btn .input-data button{
    display: block;
   }
   .another-btn{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
   }
}

@media (max-width:1100px){
  .QRCode__div img{
    width: 350px;
  }
}
@media (max-width: 856px){
  .funding-container{
    width: 80%;
    padding: 25px 25px 15px 25px;
  }
}

@media (max-width: 742px){
  form .form-row .input-data{
    margin: 0 10px;
  }
}
@media (max-width: 700px) {
  .funding-container .text{
    font-size: 30px;
  }
  .funding-container form{
    padding: 10px 0 0 0;
  }
  .funding-container form .form-row{
    display: block;
  }
  form .form-row .input-data{
    margin: 35px 0!important;
  }
  .sampleAmountDiv{
    gap: 7px;
  }
}
@media (max-width: 701px){
  .funding-container form .submit-btn{
    display: none;
  }
  .QRCode__div img{
    width: 320px;
  }
}
/* @media (max-width: 600px){
  
} */
@media (max-width: 618px){
  .account__details p{
    font-size: 19px;
  }
  .QRCode__div{
    width: 95%;
  }
}
@media (max-width: 435px){
  .QRCode__div{
    width: 100%;
  }
}
@media (max-width:410px){
  .funding-container{
    width: 93%;
  }
  .QRCode__div{
    width: 100%;
  }
}
@media (max-width:376px){
  .input-data label,
  .input-data input{
    font-size: 14px;
  }
  .input-data select{
    font-size: 15px;
  }
  .QRCode__div img{
    width: 95%;
  }
}

@media(max-width:350px){
  .funding-container .text{
    font-size: 24px;
  }

  .funding-container{
    width: 93%;
  }
  .submit-btn .input-data input{
    font-size: 15px;
  }
  .submit-btn .input-data{
    width: 170px !important;
  }
}

@media (max-width: 290px){
  .funding-container{
    padding: 25px 20px 10px 20px;
  }
}