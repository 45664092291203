#blog_contain
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

}
#card_contain
{
    border: 2px solid white;
    width: 900px;
    /* height: 600px; */

    background-color:white ;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
#side_contain
{
   background-color: white;
    border-left: 2px solid #c4c4c4;
    margin-top: 20px;
    width:300px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    /* height: 1900px; */
    flex-direction: column;
}
#side_contain_hustle
{
    /* border: 2px solid black; */
    display: flex;
    /* position: sticky; */
    top: 5px;
   /* height: 500px; */
   
}
@media (min-width:630px) and (max-width:1200px)
{
    #side_contain
{
    background-color: white;
    border-left: none;
    width:700px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    /* height: 1900px; */
    flex-direction: row;
}
}
@media (min-width:120px) and (max-width:630px)
{
    #side_contain
    {
border-left: none;
    }
}