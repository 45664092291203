.displaycontact_displayContactBody{
    max-width: 100%;
}
.displaycontact_downloadCsv{
    font-size: 30px;
    color: grey;
   margin-top: 22px;
}
.displaycontact_contactResponse{
    display: flex;
    justify-content: space-between;
    width: 93%;
    /* height: 59px; */
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    background: #EBEBEB;
    border-radius: 10px;
    padding: 5px 20px 5px 20px;
}
.displaycontact_conResData{
    display: flex;
    line-height: 49px;
    gap: 15vw;
    font-weight: 600;
}
.displaycontact_contactResTable{
    width: 93%;
    margin: 10px auto 20px auto;
}
.displaycontact_heading{
    text-align:center;
    width:30%;
    padding:10px;
    border:1px solid black;
}