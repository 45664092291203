.ProfInchargesandManagers-Managers-Heading {
  width: 35%;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(39, 38, 38);
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}
